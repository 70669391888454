window.gtagLogButtonClick = (event, parameters) => {
    if (typeof gtag !== 'function') return

    gtag(
        'event',
        event,
        parameters
    )
}

window.addEventListener('load', function () {
    const closeButtons = document.getElementsByName('close-button')
    const actionButtons = document.getElementsByName('action-button')
    const appDownloadButtons = document.getElementsByClassName('app-download-button')
    const overlay = document.getElementById('overlay')
    const overlayBackground = document.getElementById('overlay-background')
    const body = document.querySelector('body')
    const accordionItems = document.querySelectorAll('[data-accordion-item]')
    const assetPlus = document.querySelector('[data-asset-plus]').getAttribute('data-asset-plus')
    const assetMinus = document.querySelector('[data-asset-minus]').getAttribute('data-asset-minus')

    const closeAllAccordionItems = () => {
        accordionItems.forEach(item => {
            if (item.classList.contains('active')) {
                handleAccordion(item)
            }
        })
    }

    const handleAccordion = (item) => {
        const image = item.querySelector('[data-accordion-image]')
        const content = item.querySelector('[data-accordion-content]')

        item.classList.toggle('active')

        if (item.classList.contains('active')) {
            image.src = assetMinus
            content.classList.remove('hidden')
        } else {
            image.src = assetPlus
            content.classList.add('hidden')
        }
    }

    const activateOverlay = event => {
        const {currentTarget: {dataset: {type, position}}} = event
        overlay.dataset.type = type
        overlay.dataset.position = position
        overlay.classList.remove('hidden')
        body.classList.add('overflow-y-hidden')
    }

    const deactivateOverlay = () => {
        overlay.classList.add('hidden')
        body.classList.remove('overflow-y-hidden')
    }

    const actionButtonClick = event => {
        activateOverlay(event)
        const {currentTarget: {dataset: {type, position}}} = event

        gtagLogButtonClick('club_lp_button_click', {type, position})
    }

    const appDownloadButtonClick = ({currentTarget: {dataset}}) => {

        let {type, position, app} = dataset

        let {type: overlayType, position: overlayPosition} = overlay.dataset
        if (!position && overlayPosition) position = overlayPosition
        if (!type && overlayType) type = overlayType

        gtagLogButtonClick('club_lp_app_button_click', {type, position, app})
    }

    accordionItems.forEach(item => {
        const trigger = item.querySelector('[data-accordion-trigger]')

        trigger.addEventListener('click', () => {
            closeAllAccordionItems()
            handleAccordion(item)
        })
    })

    actionButtons.forEach(function (elem) {
        elem.addEventListener('click', actionButtonClick)
    })

    appDownloadButtons.forEach(elem => {
        elem.addEventListener('click', appDownloadButtonClick)
    })

    closeButtons.forEach(function (elem) {
        elem.addEventListener('click', deactivateOverlay)
    })

    overlayBackground.addEventListener('click', deactivateOverlay)
})
